/*
 =========================================================
 * Vue Black Dashboard - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/black-dashboard
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch';
import App from './App';
// TIP: change to import router from "./router/starterRouter"; to start with a clean layout
import router from './router/index';
import store from './store';
import axios from './axios';

import BlackDashboard from './plugins/blackDashboard';
import i18n from './i18n';
import './registerServiceWorker';
import Cookies from '@/plugins/cookies';

Vue.use(Cookies);
Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(axios);

/* eslint-disable no-new */
const app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
});

store.dispatch('auth/setup').then(() => {
  app.$mount('#app');
});
