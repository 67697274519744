<template id="pagination">
  <ul class="pagination" aria-label="Page navigation">
    <li>
      <base-button
        size="sm"
        type="button"
        @click="onClickFirstPage"
        :disabled="isInFirstPage"
        aria-label="Go to the first page"
      >First
      </base-button
      >
    </li>
    <li>
      <base-button
        size="sm"
        type="button"
        @click="onClickPreviousPage"
        :disabled="isInFirstPage"
        aria-label="Previous"
      >«
      </base-button
      >
    </li>
    <li v-for="page in pages">
      <base-button
        size="sm"
        type="button"
        @click="onClickPage(page.name)"
        :disabled="page.isDisabled"
        :class="{ active: isPageActive(page.name) }"
        :aria-label="`Go to page number ${page.name}`"
      >
        {{ page.name + 1 }}
      </base-button
      >
    </li>
    <li>
      <base-button
        size="sm"
        type="button"
        @click="onClickNextPage"
        :disabled="isInLastPage"
        aria-label="Next"
      >
        »
      </base-button
      >
    </li>
    <li>
      <base-button
        size="sm"
        type="button"
        @click="onClickLastPage"
        :disabled="isInLastPage"
        aria-label="Go to the last page"
      >Last
      </base-button
      >
    </li>
  </ul>
</template>

<script>
export default {
  name: "base-pagination",
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 6,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isInFirstPage() {
      return this.currentPage === 0;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
    startPage() {
      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons + 1;
      }
      return this.currentPage - 1;
    },
    endPage() {
      return Math.min(
        this.startPage + this.maxVisibleButtons - 1,
        this.totalPages
      );
    },
    pages() {
      const range = [];
      for (let i = this.startPage; i <= this.endPage; i += 1) {
        if (i >= 0) {
          range.push({
            name: i,
            isDisabled: i === this.currentPage,
          });
        }
      }
      return range;
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit("pagechanged", 0);
    },
    onClickPreviousPage() {
      this.$emit("pagechanged", this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit("pagechanged", page);
    },
    onClickNextPage() {
      this.$emit("pagechanged", this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit("pagechanged", this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  },
};
</script>
<style></style>
