const getDefaultState = () => ({
  id: 0,
  username: '',
  email: '',
  genderId: null,
  accountTypeId: null,
  orientationId: null,
  mainPhotoId: null,
  created: '',
  updated: null,
  isVerified: true,
  county: '',
  city: '',
  country: '',
  countryCode: '',
  latitude: null,
  longitude: null,
  description: null,
  telephone: null,
  rating: 0,
  reviews: [],
  services: [],
  photos: [],
  birthDate: null,
  estimatedCounty: null,
  acceptedCookiesPolicy: false,
  allowSearchEngine: true,
  lastRefresh: null,
  hasCompletedProfile: false,
  hasWhatsapp: false,
});

const profileFields = [
  'accountTypeId',
  'birthDate',
  'genderId',
  'orientationId',
  'description',
  'telephone',
  'latitude',
  'longitude',
  'mainPhotoId',
];

const state = getDefaultState;

const actions = {
  updateUserObject({state, commit, rootGetters}, user) {
    commit('update', user);
  },

  refresh({commit}) {
    return new Promise((resolve) => {
      this.$axios.get('account/profile').then((response) => {
        commit('update', response.data);
        this.$eventbus.$emit('user/refresh');
        resolve();
      });
    });
  },
};

const mutations = {
  username(state, username) {
    state.username = username;
  },
  clear(state) {
    const defaultState = getDefaultState();
    defaultState.estimatedCounty = state.estimatedCounty;
    defaultState.acceptedCookiesPolicy = state.acceptedCookiesPolicy;
    Object.assign(state, defaultState);
  },
  update(state, user) {
    Object.assign(state, user);
  },
};

const getters = {};

module.exports = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
