import Vue from 'vue';
import Vuex from 'vuex';
import user from '@/store/user';
import * as constants from '@/store/constants';
import * as auth from '@/store/auth';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    auth,
    constants,
  },
  state: {},
  getters: {},
  actions: {},
  mutations: {},
});
