<template>
  <div>
    <table class="table tablesorter" :class="tableClass">
      <thead :class="theadClasses">
      <tr>
        <slot name="columns">
          <th v-for="column in columns" :key="column">
            {{ column }}
          </th>
        </slot>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in data" :key="index">
        <slot :row="item">
          <td
            v-for="(column, index) in columns"
            :key="index"
            v-if="hasValue(item, column)"
          >
            {{ itemValue(item, column) }}
          </td>
        </slot>
        <td v-if="hasButton">
          <base-button :link="hasLink" v-if="hasLink">
            <router-link
              :to="{
                                    path: `${linkTo.path}`,
                                    query: {
                                        username: `${itemValue(
                                            item,
                                            `${linkTo.query}`
                                        )}`,
                                    },
                                }"
            >
              {{ buttonText }}
            </router-link>
          </base-button
          >
          <base-button v-else>{{ buttonText }}</base-button>
        </td>
        <td v-if="hasExpandData">
          <base-button size="sm" @click="expandData(index)">
            <i class="fa fa-info-circle"></i
            ></base-button>
        </td>
      </tr>
      </tbody>
    </table>
    <Pagination
      :total-pages="data.length"
      :current-page="currentPage"
      @pagechanged="onPageChange"
    />
  </div>
</template>
<script>
import BaseButton from "./BaseButton.vue";
import Pagination from "./Pagination.vue";

export default {
  components: {Pagination, BaseButton},
  name: "base-table",
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: "Table columns",
    },
    data: {
      type: Array,
      default: () => [],
      description: "Table data",
    },
    type: {
      type: String, // striped | hover
      default: "",
      description: "Whether table is striped or hover type",
    },
    theadClasses: {
      type: String,
      default: "",
      description: "<thead> css classes",
    },
    hasButton: {
      type: Boolean,
      default: false,
    },
    hasExpandData: {
      type: Boolean,
      default: false,
    },
    hasLink: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: "",
    },
    linkTo: {
      type: Object,
      default: () => {
      },
    },
    expandData: {
      type: Function,
      default: () => {
      },
    },
    onPageChange: {
      type: Function,
      default: () => {
      },
    },
    currentPage: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    itemValue(item, column) {
      return item[column];
    },
    previousPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    nextPage() {
      if (this.currentPage * this.pageSize < this.table.data.length)
        this.currentPage++;
    },
  },
};
</script>
<style></style>
