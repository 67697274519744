export const ASSETS_URL = 'https://cdn.intalniri24.ro/';
export const TRANS_ORIENTATION_NAMES = ['a', 'p', 'ap'];
export const TRANS_GENDER_SYMBOL = 'T';

export const MIN_AGE = 18;
export const MAX_AGE = 70;
export const MAX_NUMBER_OF_SERVICES_IN_FILTERS_STRING = 2;
export const HOURS_BETWEEN_PROMOTES = 4;

export const COOKIES_ACCEPTED_VALUE = 'yes';

export const NO_SEARCH_RESULTS_TYPE = 'no_results';
export const NO_MORE_SEARCH_RESULTS_TYPE = 'no_more_results';
export const SEARCH_ACTION_REMOVE_AGES_FILTER = 'remove_ages_filter';
export const SEARCH_ACTION_REMOVE_ORIENTATION_FILTER =
  'remove_orientation_filter';

export const PhotosGalleryEvents = {
  MAIN_PHOTO_CHANGED: 1,
  GALLERY_CLOSED: 2,
  PHOTO_DELETED: 3,
};

export const GENDER_IMAGES = {
  M: require('../assets/images/profile-completion/icon-gender-man.png'),
  F: require('../assets/images/profile-completion/icon-gender-woman.png'),
  T: require('../assets/images/profile-completion/icon-gender-trans.png'),
};
