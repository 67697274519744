import find from 'lodash/find';
import isNumber from 'lodash/isNumber';
import intersection from 'lodash/intersection';
import defaultTo from 'lodash/defaultTo';
import isObject from 'lodash/isObject';
import {
  TRANS_GENDER_SYMBOL,
  TRANS_ORIENTATION_NAMES,
  MIN_AGE,
  MAX_AGE,
  MAX_NUMBER_OF_SERVICES_IN_FILTERS_STRING,
  SEARCH_ACTION_REMOVE_AGES_FILTER,
  SEARCH_ACTION_REMOVE_ORIENTATION_FILTER,
  ASSETS_URL,
} from '@/utils/constants';

export function formatServices(services, keyedServices) {
  return services.map((service) => {
    service.name = keyedServices[service.serviceId].name;
    service.description = keyedServices[service.serviceId].description;
    service.code = keyedServices[service.serviceId].code;
    return service;
  });
}

export function getAgeFiltersString(filters, i18n) {
  let text = '';
  if (filters.fromAge && filters.toAge) {
    let localeKey = 'between';
    if (filters.fromAge === filters.toAge) localeKey = 'of';
    else if (filters.toAge === MAX_AGE) localeKey = 'over';

    text = i18n.t(`global.filtersString.age.${localeKey}`, {
      start: filters.fromAge,
      stop: filters.toAge,
    });
  }

  return text;
}

export function getFiltersString(filters, i18n) {
  const text = [];
  if (filters.gender) {
    text.push(
      `<span>${i18n.t(`global.gender.plural.${filters.gender.symbol}`)}</span>`
    );
    if (filters.orientation && filters.orientation.name !== 'ap')
      text.push(
        `<span>${i18n
          .t(`global.orientation.plural.${filters.orientation.name}`)
          .toLowerCase()}</span>`
      );
  }

  if (filters.county) {
    text.push(
      i18n.t('global.filtersString.location', {
        location: filters.county.name,
      })
    );
  }

  if (filters.fromAge && filters.toAge) {
    text.push(getAgeFiltersString(filters, i18n));
  }

  if (filters.services && filters.services.length > 0) {
    const localeKey = `global.filtersString.services.${
      filters.gender ? filters.gender.symbol : 'F'
    }`;
    const services = filters.services
      .slice(0, MAX_NUMBER_OF_SERVICES_IN_FILTERS_STRING)
      .map(({name}) => name);
    if (filters.services.length > 2) {
      services.push(
        `+ ${filters.services.length -
        MAX_NUMBER_OF_SERVICES_IN_FILTERS_STRING}`
      );
    }
    text.push(
      i18n.t(localeKey, {
        services: services.join(', '),
      })
    );
  }

  return text.join(' ');
}

// Defaults
export function getDefaultCounty(store) {
  return find(store.state.constants.countyTypeList, [
    'code',
    store.state.user.estimatedCounty.code,
  ]);
}

export function getDefaultGender(store) {
  return find(store.state.constants.genders, ['symbol', 'F']);
}

export function getCountyObjectFromCode(code, store) {
  if (!code) return null;
  const county = find(store.state.constants.countyTypeList, [
    'code',
    code.toUpperCase(),
  ]);
  if (county) return county;
  return null;
}

export function getGenderFromId(genderId, store) {
  if (!genderId) return null;
  const gender = find(store.state.constants.genders, [
    'id',
    parseInt(genderId),
  ]);
  if (gender) return gender;
  return null;
}

export function getGenderFromSymbol(genderSymbol, store) {
  if (!genderSymbol) return null;
  const gender = find(store.state.constants.genders, ['symbol', genderSymbol]);
  if (gender) return gender;
  return null;
}

export function getOrientationFromId(orientationId, store) {
  if (!orientationId) return null;
  const orientation = find(store.state.constants.orientations, [
    'id',
    parseInt(orientationId),
  ]);

  if (orientation) return orientation;
  return null;
}

export function getOrientationFromName(orientationName, store) {
  if (!orientationName) return null;
  const orientation = find(store.state.constants.orientations, [
    'name',
    orientationName,
  ]);

  if (orientation) return orientation;
  return null;
}

export function getOrientationFromIdForGender(orientationId, gender, store) {
  if (!orientationId) return null;
  const orientation = getOrientationFromId(orientationId, store);

  // gender can be either object or gender id
  if (!isObject(gender))
    gender = defaultTo(getGenderFromId(gender, store), getDefaultGender(store));
  if (orientation && orientationBelongsToGender(orientation, gender)) {
    return orientation;
  }

  return getDefaultOrientationForGender(gender, store);
}

export function orientationBelongsToGender(orientation, gender) {
  if (isObject(orientation)) orientation = orientation.name;
  if (isObject(gender)) gender = gender.symbol;

  return (
    (gender === TRANS_GENDER_SYMBOL &&
      TRANS_ORIENTATION_NAMES.includes(orientation)) ||
    (gender !== TRANS_GENDER_SYMBOL &&
      !TRANS_ORIENTATION_NAMES.includes(orientation))
  );
}

export function getDefaultOrientationForGender(gender, store) {
  // gender can be either object or gender id
  if (!isObject(gender)) gender = getGenderFromId(gender, store);

  // gender should never be null here
  if (!gender) return null;

  if (gender.symbol !== TRANS_GENDER_SYMBOL) return null;
  return getOrientationFromName('ap', store);
}

export function getFiltersFromRoute(route, store) {
  // console.log('get filters from', route.query, store.state.constants)
  const county = defaultTo(
    getCountyObjectFromCode(route.query.county, store),
    getDefaultCounty(store)
  );
  const gender = defaultTo(
    getGenderFromId(route.query.gender, store),
    getDefaultGender(store)
  );
  const orientation = defaultTo(
    getOrientationFromIdForGender(route.query.orientation, gender, store),
    getDefaultOrientationForGender(gender, store)
  );

  // ages
  const fromAgeNumber = parseInt(route.query['from-age']);
  let fromAge =
    isNumber(fromAgeNumber) && !isNaN(fromAgeNumber) ? fromAgeNumber : MIN_AGE;
  const toAgeNumber = parseInt(route.query['to-age']);
  let toAge =
    isNumber(toAgeNumber) && !isNaN(toAgeNumber) ? toAgeNumber : MAX_AGE;

  if (fromAge < MIN_AGE) fromAge = MIN_AGE;
  else if (fromAge > MAX_AGE) fromAge = MAX_AGE;

  if (toAge < MIN_AGE) toAge = MIN_AGE;
  else if (toAge > MAX_AGE) toAge = MAX_AGE;

  if (fromAge > toAge) fromAge = toAge;

  let services = [];
  if (route.query.services) {
    try {
      const storeServices = store.state.constants.services;
      const serviceIds = route.query.services
        .split(',')
        .map((id) => parseInt(id));

      services = intersection(
        serviceIds,
        storeServices.map((service) => service.id)
      ).map((serviceId) => find(storeServices, ['id', serviceId]));
    } catch (e) {
      services = [];
    }
  }
  return {
    county,
    fromAge,
    toAge,
    services,
    gender,
    orientation,
    longitude: null,
    latitude: null,
  };
}

export function getRouteQueryFromFilters(filters) {
  filters = getFiltersQueryObject(filters);
  const query = {
    'from-age': filters.fromAge,
    'to-age': filters.toAge,
    gender: filters.gender,
    county: filters.county,
  };

  if (filters.services && filters.services.length > 0)
    query.services = filters.services.join(',');

  if (filters.orientation) query.orientation = filters.orientation;

  if (filters.longitude) query.longitude = filters.longitude;

  if (filters.latitude) query.latitude = filters.latitude;
  return query;
}

export function getFiltersQueryObject(filters) {
  return {
    county: filters.county.code,
    fromAge: filters.fromAge,
    toAge: filters.toAge,
    services: filters.services.map(({id}) => id),
    gender: filters.gender.id,
    orientation: filters.orientation ? filters.orientation.id : null,
    longitude: filters.longitude,
    latitude: filters.latitude,
    page: filters.page,
    number: 10,
  };
}

export function getSearchNoResultsData(filters, type, i18n) {
  // console.log('ss', filters)
  const text = i18n.t(`search.noResults.text.${type}`);
  if (filters.toAge !== MAX_AGE || filters.fromAge !== MIN_AGE) {
    return {
      text,
      button: {
        text: i18n.t('search.noResults.button.showAllAges'),
        action: SEARCH_ACTION_REMOVE_AGES_FILTER,
      },
    };
  } else if (
    filters.orientation !== null &&
    filters.orientation.name !== 'ap'
  ) {
    return {
      text,
      button: {
        text: i18n.t('search.noResults.button.showAllOrientations'),
        action: SEARCH_ACTION_REMOVE_ORIENTATION_FILTER,
      },
    };
  }

  return {text, button: null};
}

export function getPhotoById(photoId, photos) {
  const photo = find(photos, ['id', photoId]);
  if (photo) return `${ASSETS_URL}${photo.thumbnail}`;
  return `${ASSETS_URL}default-main-photo.jpg`;
}

export function getPhotoUrl(photoName) {
  return `${ASSETS_URL}${photoName}?2`;
}
