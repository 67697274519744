import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://dev.api.intalniri24.ro/',
});

export default {
  install: function (Vue) {
    Vue.prototype.$axios = instance;
  },
};
export const Axios = instance;
