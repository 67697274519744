import {Cookie} from '@/plugins/cookies';
import {Axios} from '@/axios';
import router from '@/router';

export const namespaced = true;

export const state = () => ({
  token: Cookie.get('token') || '', // take token and refreshToken from cookies (vue-cookies)
  refreshToken: Cookie.get('refreshToken') || '',
});

export const mutations = {
  token(state, token) {
    state.token = token;
  },
  refreshToken(state, refreshToken) {
    state.refreshToken = refreshToken;
  },
};

export const actions = {
  setup({dispatch}) {
    return dispatch('constants/setup', null, {root: true}).then(() => {
      return dispatch('refreshToken').then(() => dispatch('checkInitialToken'));
    });
  },

  handleSuccessfulLogin(
    {commit, dispatch},
    {token, refreshToken = null, user}
  ) {
    dispatch('user/updateUserObject', user, {root: true});
    return dispatch('updateTokens', {token, refreshToken});
  },

  checkInitialToken({state, dispatch}) {
    // we get tokens from store because they are refreshed before this action
    const token = state.token;
    const refreshToken = state.refreshToken;
    // console.log('checking tokens', token, refreshToken)
    if (!token) return Promise.resolve();
    return new Promise((resolve) => {
      Axios.get('account/profile')
        .then((response) => {
          // console.log('handling succesful login', token, refreshToken)
          dispatch('handleSuccessfulLogin', {
            token,
            refreshToken,
            user: response.data,
          }).then(resolve);
        })
        .catch((error) => {
          // console.log(
          // 	'token check fail',
          // 	error,
          // 	error.response.data,
          // 	error.response.status,
          // 	JSON.stringify(error.response.headers)
          // )
          dispatch('logout').then(resolve);
        });
    });
  },

  logout({commit}, {redirectTo = '/login'} = {}) {
    delete Axios.defaults.headers.common.Authorization;
    delete Axios.defaults.headers.common.authorization;
    commit('token', '');
    commit('refreshToken', '');
    commit('user/clear', '', {root: true});
    Cookie.remove('token');
    Cookie.remove('refreshToken');
    router.push(redirectTo);
    return Promise.resolve();
  },

  refreshToken({dispatch}) {
    const refreshToken = Cookie.get('refresh_token');
    // remove cookies in case is not ok so we avoid infinite loop because we get token_expired if refresh token not ok
    Cookie.remove('token');
    Cookie.remove('refreshToken');
    if (
      refreshToken === null ||
      typeof refreshToken === 'undefined' ||
      refreshToken.length === 0
    ) {
      // console.log('refresh token is null')
      return Promise.resolve();
    }

    Cookie.remove('token');
    Cookie.remove('refreshToken');
    // console.log('checking refresh token', refreshToken)
    return new Promise((resolve) => {
      Axios.post(
        'Account/refresh-token',
        {},
        {
          params: {refreshToken},
        }
      )
        .then((response) => {
          const newToken = response.data.jwtToken;
          const newRefreshToken = response.data.refreshToken;
          // console.log('got new tokens', {
          // 	token: newToken,
          // 	refreshToken: newRefreshToken,
          // })
          return dispatch('updateTokens', {
            token: newToken,
            refreshToken: newRefreshToken,
          }).then(resolve);
        })
        .catch((error) => {
          // console.log(
          // 	'refresh token error',
          // 	error,
          // 	error.response.data
          // )
          resolve();
        });
    });
  },

  updateTokens({commit}, {token, refreshToken = null} = {}) {
    const day = new Date();
    day.setDate(day.getDate() + 7);

    Cookie.set('token', token, {
      path: '/',
      expires: day,
      sameSite: 'lax',
    });
    commit('token', token);

    if (refreshToken !== null && refreshToken.length !== 0) {
      Cookie.set('refresh_token', refreshToken, {
        path: '/',
        expires: day,
        sameSite: 'lax',
      });
      commit('refreshToken', refreshToken);
    }

    Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    return Promise.resolve();
  },
};

export const getters = {
  isLoggedIn: (state) => state.token !== '',
};
