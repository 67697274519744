import keyBy from 'lodash/keyBy';
import {Axios} from '@/axios';
import {Cookie} from '@/plugins/cookies';
import I18n from '@/i18n';
import {formatServices} from '@/utils/functions';
import {
  GENDER_IMAGES,
  COOKIES_ACCEPTED_VALUE,
  NO_SEARCH_RESULTS_TYPE,
  NO_MORE_SEARCH_RESULTS_TYPE,
} from '@/utils/constants';

export const namespaced = true;

export const state = () => ({
  accountTypes: [],
  genders: [],
  orientations: [],
  services: [],
  reportTypes: [],
  globalLocales: {},
  countyTypeList: [],
  photoStatusTypeList: [],
});

export const mutations = {
  accountTypes(state, types) {
    state.accountTypes = types;
  },
  photoStatusTypeList(state, photoStatusTypeList) {
    state.photoStatusTypeList = photoStatusTypeList;
  },
  genders(state, genders) {
    state.genders = genders;
  },
  countyTypeList(state, countyTypeList) {
    state.countyTypeList = [
      {
        id: 'RO',
        code: 'RO',
        name: state.globalLocales.global.filters.allCounties,
        latitude: 0,
        longitude: 0,
      },
      ...countyTypeList,
    ];
  },
  orientations(state, orientations) {
    state.orientations = orientations;
  },
  services(state, services) {
    state.services = services;
  },
  reportTypes(state, reportTypes) {
    state.reportTypes = reportTypes;
  },
  globalLocales(state, globalLocales) {
    state.globalLocales = globalLocales;
  },
};

export const getters = {
  formattedServices(state, getters) {
    return formatServices(state.services, getters.keyedServices);
  },

  keyedServices(state) {
    return keyBy(state.services, 'serviceId');
  },

  keyedAccountTypes(state) {
    return keyBy(state.accountTypes, 'id');
  },

  keyedGenders(state) {
    return keyBy(state.genders, 'id');
  },
  keyedPhotoStatusType(state) {
    return keyBy(state.photoStatusTypeList, 'name');
  },

  formattedGenders(state) {
    return state.genders.map((gender) => {
      gender.image = GENDER_IMAGES[gender.symbol];
      gender.localeKey = `global.gender.${gender.symbol}`;

      return gender;
    });
  },
  formattedOrientations(state) {
    return state.orientations.map((orientation) => {
      orientation.localeKey = `global.orientation.${orientation.name}`;

      return orientation;
    });
  },

  getAccountTypes(state) {
    return state.accountTypes;
  },
};

export const actions = {
  setup({dispatch, commit, state}) {
    return dispatch('loadGlobalLanguage')
      .then(() => dispatch('populate'))
      .then((county) => {
        const defaultCounty = find(state.countyTypeList, ['code', 'RO-B']);

        // RE-ENABLE ESTIMATED COUNTY AFTER MORE USERS ARE SIGNED UP
        // RE-ENABLE BY COMMENTING THIS
        county = find(state.countyTypeList, ['code', 'RO']);

        commit(
          'user/update',
          {estimatedCounty: county || defaultCounty},
          {root: true}
        );

        return Promise.resolve();
      })
      .then(() => {
        const acceptedCookiesPolicy =
          Cookie.get('acceptedCookiesPolicy') === COOKIES_ACCEPTED_VALUE;
        commit(
          'user/update',
          {
            acceptedCookiesPolicy,
          },
          {root: true}
        );
        return Promise.resolve();
      });
  },
  loadGlobalLanguage({commit}) {
    return Axios.get('https://www.intalniri24.ro/translations/ro.json').then(
      ({data}) => {
        // hack
        data.search = {
          noResults: {
            text: {
              [NO_SEARCH_RESULTS_TYPE]: 'Nu există rezultate',
              [NO_MORE_SEARCH_RESULTS_TYPE]: 'Ai ajuns la capătul listei',
            },
            button: {
              showAllAges: 'Arată toate vârstele',
              showAllOrientations: 'Arată toate orientările',
            },
          },
        };

        I18n.mergeLocaleMessage('ro', data);
        commit('globalLocales', data);

        return Promise.resolve();
      }
    );
  },

  updateAccountTypes({commit}, types) {
    return new Promise((resolve) => {
      commit('accountTypes', {
        SELLER: types.filter((type) => type.name === 'Seller')[0].id,
        BASIC: types.filter((type) => type.name === 'Reviewer')[0].id,
        ADMIN: types.filter((type) => type.name === 'Admin')[0].id,
      });
      resolve();
    });
  },

  getGendersAndOrientations({commit}) {
    return new Promise((resolve) => {
      Axios.get('aggregateType/list').then((response) => {
        commit('genders', response.data.genderList);
        commit('orientations', response.data.orientationList);
        resolve();
      });
    });
  },

  getReportTypes({commit}) {
    return new Promise((resolve) => {
      Axios.get('aggregateType/list').then((response) => {
        commit('reportTypes', response.data.reportTypeList);
        resolve();
      });
    });
  },

  populate({commit}) {
    return Axios.get('AggregateType/List').then((response) => {
      commit('accountTypes', response.data.accountTypeList);
      commit('genders', response.data.genderList);
      commit('orientations', response.data.orientationList);
      commit(
        'services',
        response.data.serviceList.map((service) => {
          service.serviceId = service.id;
          return service;
        })
      );
      commit('reportTypes', response.data.reportTypeList);
      commit('countyTypeList', response.data.countyTypeList);
      commit('photoStatusTypeList', response.data.photoStatusTypeList);

      return Promise.resolve();
    });
  },
};
